@import '../../../css/variables';
@import '../../../css/functions';

.practice {

	.step.intro1 { 
		width: 100%;
		text-align: center;

		.t2, .t3 { 
			color: $indigoBlue; 
			font-weight:$bold; 
		}

		.t4 { 
			color: $indigoBlue;
		}

		.panel .top { 
			width:60%;  
			display: flex; 
			flex-direction: column; 
			justify-content: center; 
			align-items: center; 

			@include breakpoint('tablet') {
				width:80%;
			}

			@include breakpoint('mobile') {
				width:100%;
			}
		}

		.bottom { 
			display: flex; 
			
			@include breakpoint('mobile') {
				flex-wrap: wrap;
			}

	// IE11 and below

	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
		width: 100%;
	}

	.panel{

		padding: remSize(12) remSize(28) remSize(6) remSize(20);
		flex-direction: column;
		align-items: flex-start;
		margin: remSize(8);
		text-align: left;
		justify-content: flex-start;
	}
}

.btn-next{ margin-bottom:30px; max-width: initial;}
}

.bottom .panel{ 
	margin:10px;

	img.icon {
		width: 2rem;
	}
}

}