@import '../../css/variables';
@import '../../css/functions';

.practice {

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 20px;
    }

   .step { 
        //padding-top:2rem;
        h3,h4 {margin-top:10px;}
    }

   //section { margin: 12px 0 20px 0; }

   

    .btn-skip { align-self: flex-end;}
    

   .bottom {
        display:flex; 
        margin-left:-10px;
        margin-right:-10px;

        @include breakpoint('tablet') {
            & {flex-wrap:wrap;}
        }
    }

 .question-container {
    display: flex;
    flex-grow: 1;
 }
}