@import '../../css/variables';
@import '../../css/common';
@import '../../css/functions';


.result {

  .btn-compact {
    border-radius: 24px;
    // padding-top: 0.625rem;
    // padding-bottom: 0.625rem;
    // padding-right: 2.5rem;
    // padding-left: 2.5rem;
    padding: 0.625rem 2.5rem;
    font-weight: bold;
    // min-width: unset;
    // min-height: unset;
    // font-size: unset;
    white-space: nowrap;
    margin: 10px;

    
  }

  .btn-start-again {
    position: absolute;
    right: 10px;
    top: 110px;
    color: $white;
    background-color: $indigoBlue;

    &.playing {
      opacity: 0.5;
    }
    &:hover{
      color:#ffffff;
      background-color: #4e03c0;
    }
    @include breakpoint('tablet') {
      position: initial;
      align-self: flex-end;
      top:0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
    @include breakpoint('tablet') {
      margin-top: 25px;
    }
  }
  h1{ text-align:center; margin-top: 20px; width:60%;
    @include breakpoint('tablet') {
      width:70%;
    }
  }
  p{ font-size: remsize(20); margin: 0;}
  .resulttext{ 
    @include breakpoint('tablet') {
      margin-top: 85px;
    }
    }
  .icon{  width:remSize(50); height: remSize(40); }


}