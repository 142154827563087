@import '../../css/variables';
@import '../../css/functions';

.btn.btn-sound {
  border-radius: 30px;
  padding: 25px 20px;
  margin: 10px;
  border: 2px solid $indigoBlue;
  color: $indigoBlue;
  font-weight: bold;
  line-height:8px;
  font-size:24px;
  min-width:200px;
  height:60px;
  text-align: center;
  align-items: center;
    @include breakpoint('mobile') {
        margin: 5px 0;
    }
    &:hover{ border:4px solid $indigoBlue; padding:21px 16px;      
    @include breakpoint('tablet') {
    border: 2px solid $indigoBlue; color: $indigoBlue;
    }
     }
    &:active{ border:4px solid $macaroni-and-cheese; padding:21px 16px;}

    &.selected{border:4px solid $macaroni-and-cheese; padding:21px 16px;}
    
    &.disabled {
        background: $grey3;
        border: 2px solid $darkgrey;
        //margin-top: 9px;
        //margin-bottom: 9px;
        padding: 25px 20px;
        color: $darkgrey;

        &:hover{box-shadow:none;}
    }
}
