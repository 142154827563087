@import '../../css/variables';
@import '../../css/functions';

.question{

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
    h1, h2, h3, h4, h5 { width: auto; }
    p { width: auto; } 
    span:not(.bar) { width: auto; } 
  }

  
  .sound-buttons {
    display: flex;
    justify-content: space-between;

    @include breakpoint('mobile') {
      & { display: block; }
    }
  }

  .help-text { color: $slate-grey; 
              font-style: italic; 
              font-size: remSize(20);
            }

  @include breakpoint('mobile') {
    h3 { width: 100%; }
  }

  .btn-spacer {
    height: 55px;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    margin: 25px 10px 10px 10px;
  }

  .footnote-text-spacer {
    height: 21px;
  }
}