@import '../../../css/variables';
@import '../../../css/functions';

.page {
  .body-content{
    .practice {
      .thanks {
        justify-content: center;
        width: 60%;
        text-align: center;
        flex-grow: 1;
        width: 100%;

        .content {
          width: 70%;
          @include breakpoint('tablet') {
            width:90%;
          }
          
        }

        h2 { color: $indigoBlue; margin-top:60px;}
        .btn-next {white-space:normal;}

        p { margin-top: 20px; margin-bottom: 20px; }

        //.t3 { font-style: italic; }
      }    
    }
  }
}