@import '../../css/variables';
@import '../../css/common';
@import '../../css/functions';


.details {

  align-items: center;
  flex-grow: 1;

  .not-visible { visibility: hidden; }

  .btn-compact {
    border-radius: 24px;
    // padding-top: 0.625rem;
    // padding-bottom: 0.625rem;
    // padding-right: 2.5rem;
    // padding-left: 2.5rem;
    padding: 0.625rem 2.5rem;
    font-weight: bold;
    // min-width: unset;
    // min-height: unset;
    // font-size: unset;
    white-space: nowrap;
    margin: 10px;
  }

  .btn-calibrate {
    position: absolute;
    right: 10px;
    top: 110px;
    color: $white;
    background-color: $indigoBlue;
    @include breakpoint('tablet') {
      top:70px;
    }

    &.playing {
      opacity: 0.5;
    }
    &:hover{
      color:#ffffff;
      background-color: #4e03c0;
    }
  }

  .form-group { margin: 10px 0; min-width: auto;}
  .content {

    width: 80%;

    .three-buttons {
      .form-group { min-width: unset; }
    }
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    // .three-items {
    //    width:722px;
    //   .form-group:nth-child(2) { min-width: 158px; width:158px;
    //     @include breakpoint('mobile'){
    //       width:100%;
    //     }
    //   }
    //   .form-group:nth-child(3) { 
    //     min-width: 112px; width:112px; margin-left:0;
    //     @include breakpoint('mobile'){
    //       margin-left:30px;
    //       width: 100%;;
    //     }  
    //   }
    //   @include breakpoint('mobile'){
    //     width:auto;
    //     min-width: inherit;

    //   }
    // }

    // .form-group {
    //   @include breakpoint('mobile'){
    //     width:100%;
    //   }
    //}
  }
  
}