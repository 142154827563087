@import '../../css/variables';
@import '../../css/functions';

.form-group { 
  min-width: 300px;
  text-align: left; 
  // margin-left:remSize(10);
  // margin-right:remSize(10);
  margin: 10px 30px;

  &.has-error input {
    background: url('/images/exclamation.png') no-repeat scroll right;
    background-size: 15px 15px;
    padding-right: 5px;
    background-origin: content-box;
    border: 1px solid $red;
  }

  &.has-error select {
    border: 1px solid $red;
  }

  .error-msg { color: $red; font-size: remSize(14); font-style: italic; display: block; }

  .control-label {
    font-size: 16px;
    color: $slate-grey;
    min-height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom:0;
    min-height: auto;

    .field-info-text {
      font-size: remSize(14);
      font-style: italic;
      color: $grey2;
      line-height:remSize(12);
      margin-bottom:5px;
      }
  }    
  .control-label[for=firstName], .control-label[for=lastName] { min-height: unset; }

      margin-top:remSize(16);
      margin-bottom:remSize(16);

      // IE11 and below
  // @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  // width: 100%;
  // }
  &.has-error {
    .control-label { color: $red; }
    }


    // @include breakpoint('tablet') {
    //   width: 100%;
    // }

}