@import '../../../css/variables';
.page{
  .practice {
    .intro2 {
      flex-grow: 1;

      .btn-skip { align-self: flex-end; }

      .btn-next {
        margin-top:25px;
     }
   }
 }
}