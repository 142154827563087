@import '../../../css/variables';

.soundbars {

  display: flex;
  align-items: center;
  height: 8px;
  width:40px;
  margin:0 auto;

  .bar {
    background: $indigoBlue;
    width: 2px;
    //opacity: 0.59;
    margin: 1px;
    
    &.bar1-animate { animation: bar-animate; animation-delay: 0.0ms; animation-duration: 0.5s;  }
    &.bar2-animate { animation: bar-animate; animation-delay: 0.2ms; animation-duration: 0.5s; }
    &.bar3-animate { animation: bar-animate; animation-delay: 0.4ms; animation-duration: 0.5s;  }
    &.bar4-animate { animation: bar-animate; animation-delay: 0.6ms; animation-duration: 0.5s;  }
    &.bar5-animate { animation: bar-animate; animation-delay: 0.8ms; animation-duration: 0.5s; }
    &.bar6-animate { animation: bar-animate; animation-delay: 1.0ms; animation-duration: 0.5s;  }
    &.bar7-animate { animation: bar-animate; animation-delay: 1.2ms; animation-duration: 0.5s;  }
    &.bar8-animate { animation: bar-animate; animation-delay: 1.4ms; animation-duration: 0.5s;  }
    &.bar9-animate { animation: bar-animate; animation-delay: 1.6ms; animation-duration: 0.5s;  }
    &.bar10-animate { animation: bar-animate; animation-delay: 1.8ms; animation-duration: 0.5s;  }
  }

  $opacity1: .15;

  .bar:nth-child(1)  { height: 5px; }
  .bar:nth-child(2)  { height: 15px; }
  .bar:nth-child(3)  { height: 26px; }
  .bar:nth-child(4)  { height: 21px; }
  .bar:nth-child(5)  { height: 31px; }
  .bar:nth-child(6)  { height: 25px; }
  .bar:nth-child(7)  { height: 18px; }
  .bar:nth-child(8)  { height: 13px; }
  .bar:nth-child(9)  { height: 10px; }
  .bar:nth-child(10) { height: 5px; }

  @keyframes bar-animate {
    0% { opacity: $opacity1; }
    100% { opacity: 1; }
  }

  // .bar:nth-child(1)  { height: 6.7px; left: 1px; animation-duration: 474ms; }
  // .bar:nth-child(2)  { height: 15.8px; left: 5px; animation-duration: 433ms; }
  // .bar:nth-child(3)  { height: 31.2px; left: 9px; animation-duration: 407ms; }
  // .bar:nth-child(4)  { height: 24.1px; left: 13px; animation-duration: 458ms; }
  // .bar:nth-child(5)  { height: 43.5px;left: 17px; animation-duration: 400ms; }
  // .bar:nth-child(6)  { height: 37px; left: 21px; animation-duration: 427ms; }
  // .bar:nth-child(7)  { height: 23.4px; left: 25px; animation-duration: 441ms; }
  // .bar:nth-child(8)  { height: 13.8px; left: 29px; animation-duration: 419ms; }
  // .bar:nth-child(9)  { height: 10.5px; left: 33px; animation-duration: 487ms; }
  // .bar:nth-child(10) { height: 6.7px; left: 37px; animation-duration: 442ms; }
}