@import '../../css/variables';
@import '../../css/functions';

.the-progress-bar {

  position: relative;
  margin-top: 25px;
  height: 50px;
  width: 100%;
  
  .progress {

    border-radius:0;
    border-bottom:2px dashed $macaroni-and-cheese;
    background:none;
    height: 5px;
    //margin-left:24px;
    
    .progress-bar {
      
      border-bottom:2px dashed $green;
      height: 5px;
      width: 0%;
      -webkit-transition: none; /* Safari */
      transition: none;
      top:0;
      position:absolute;
      background:none;
      left:0;
      //margin-left:24px;
      background: $grey1;
      }
  }

 .phases {

       
  .phase {
    position: relative;
    text-align:center;
    float:left;  
    z-index: 1;
    font-size: 0.8em;
    display: flex;
    justify-content: flex-start;

    &:nth-child(1) { width: 15%;  .phasediv{margin:0; text-align:left;}  }        // begin
    &:nth-child(2) {  width: 20%;}                                               // setup
    &:nth-child(3) {  width: 18%;}                                              // practice
    &:nth-child(4) {  width: 37%; }                                            // test
    &:nth-child(5) {  width: 10%; .phasediv{margin:0; text-align:right;} }    // result

    .phasediv{margin:0 auto; width:100%; height:45px;}
    
    .text{position:relative;left:0; top:-24px; font-size:16px; color:$black;}
    .text{display:none;}
    &.active { 

      @include breakpoint('mobile') {
       .text{display:none;}        
    }

    .text{display:block;}
     }



    span { 
      position: relative;
      top: 5px;
      left: -15px
            }

    .dot {
      height: 28px;
      width: 28px;
      background-color: $grey;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      top: -16px;
      margin:0 auto;
      font-size:14px;

      

      &.visited { 
         background: $macaroni-and-cheese;
         top:-14px;

        .inner {
          content: " ";
          display: block;
          width: 0.5em; // the short bar of the mark is half as long as the long bar
          height: 0.8em;
          border: solid white;
          border-width: 0 0.2em 0.2em 0; // creates the inverted "L" shape
          position: absolute;
          left: 9px;
          top: 40%;
          margin-top: -0.2em;
          -webkit-transform: rotate(45deg); // tilts the shape
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);}

          .phase-step{ display:none;}

           }

         .phase-step{ position:absolute;left: 50%;transform: translateX(-50%); top:0; color:#7b787b; font-weight:$bold; padding-top:4px; text-align:center;} 

          &.active {
            background: $macaroni-and-cheese;
            .phase-step{ color:$black;}
                }
              }

              
        }


    }
}

