@import '../../css/variables';

.login {

  align-items: center;
  flex-grow: 1;

  .form-error {
    color: $red;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
  }

}