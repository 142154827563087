@import '../../css/variables';

.test {

	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	.content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		margin-bottom: 20px;
	}

	section { margin-bottom: 10px; }

	.step{ padding-top:60px;
		h3,h4 {margin-top:10px;}
	}

	.subtitle { 
		margin: 30px 0 48px 0;
	}
	.question-container {
		display: flex;
		flex-grow: 1;

	  .title{ margin-bottom:30px;}	
	 }
}